.accordion {
    margin: 0!important;
}

.accordion:last-child {
    box-shadow: none;
}

.accordion div.MuiAccordionSummary-content {
    margin: 8px 0;
}

.accordion div.MuiAccordionSummary-root {
    min-height: 48px!important;
}

.accordion .MuiTypography-root {
    font-size: 14px!important;
}